exports.components = {
  "component---src-pages-cookie-policy-en-jsx": () => import("./../../../src/pages/cookie-policy.en.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-en-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-politique-confidentialite-jsx": () => import("./../../../src/pages/politique-confidentialite.jsx" /* webpackChunkName: "component---src-pages-politique-confidentialite-jsx" */),
  "component---src-pages-politique-gestion-temoins-navigation-jsx": () => import("./../../../src/pages/politique-gestion-temoins-navigation.jsx" /* webpackChunkName: "component---src-pages-politique-gestion-temoins-navigation-jsx" */),
  "component---src-pages-privacy-en-jsx": () => import("./../../../src/pages/privacy.en.jsx" /* webpackChunkName: "component---src-pages-privacy-en-jsx" */),
  "component---src-pages-restaurant-en-jsx": () => import("./../../../src/pages/restaurant.en.jsx" /* webpackChunkName: "component---src-pages-restaurant-en-jsx" */),
  "component---src-pages-restaurant-jsx": () => import("./../../../src/pages/restaurant.jsx" /* webpackChunkName: "component---src-pages-restaurant-jsx" */)
}

